import React from "react";
import Layout from "../component/Layout/Layout";
import {useStaticQuery, graphql} from "gatsby";
import Seo from "../component/Seo/Seo";
import loadable from '@loadable/component'

const Compare = loadable(() => import('../component/Compare/Compare'))


const isBrowser = typeof window !== "undefined";

const CompareOffers: React.FC = ({location}: any) => {
  const {wpPage} = useStaticQuery(graphql`
    query CompareOffersSeo {
      wpPage(slug: {eq: "porownanie-ofert-ikze"}) {
        seo {
          metaDesc
          title
          focuskw
        }
      }
    }
  `);
  
  // let dateOfBirth:( string | null) = null
  let age:( string | null) = null
  let amount:(number | null | string)  = 0
  if (isBrowser) {
    const params = new URLSearchParams(location.search);
    // dateOfBirth = params.get("data_urodzenia") || null;
    age = params.get("wiek") || null;
    amount = params.get("kwota_oszczednosci") || null;
  }
  

  return (
    <Layout>
      <Seo
        title={wpPage.seo.title}
        description={wpPage.seo.metaDesc}
        keywords={wpPage.seo.metaDesc}
        pathname="/porownanie-ofert-ikze/"
      />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Compare age={age} amount={amount} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CompareOffers;
